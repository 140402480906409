import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loading from '../../../components/Elements/Loading/Loading';
import styles from '../PerfilProposta.module.css';
import TabelaDadosCarroProposta from '../../../components/Propostas/TabelaDadosDoCarro';
import FormCamposCalculoPropostaPT from '../../../components/Propostas/FormCamposCalculoProposta_PT/FormCamposCalculoProposta_ISV_IUC';
import PrecoFinalProposta from '../../../components/Propostas/PrecoFinalProposta';
import EstimativaDaViatura from '../../../components/Propostas/EstimativaDaViatura';
import InfosBusinessDeveloper from '../../../components/InfosBusinessDeveloper/InfosBusinessDeveloper';
import HeroProposta from '../../../components/HeroProposta/HeroProposta';
import AceitarProposta from '../../../components/Elements/AceitarProposta/AceitarProposta';
import Partilhar from '../../../components/Elements/Partilhar/Partilhar';
import ExpressProposta from '../../../components/Propostas/ExpressProposta/ExpressProposta';

export default function PerfilDaPropostaPT({ parametros, propostaData, isDarkTheme, onDataLoaded }) {


    // const [loading, setLoading] = useState(true);
    const [dataCarros, setDataCarros] = useState([]);

    const [bdInfo, setBDInfo] = useState([]);

    const [propostaInfo, setPropostaInfo] = useState({
        linkFora: '',
        linkDaProposta: '',
        estadoDaProposta: '',
        idProposta: '',
        standData: [],
        tipoNegocio: '',
        pais: '',
        outros: [],
        dataExpira: '',
        nomeCliente: '',
        linkDocumentacao: '',
    });

    const [isvIucData, setIsvIucData] = useState({
        cilindrada: 0,
        novoUsado: '',
        tipoCombustivel: '',
        emissoesCO2: '',
        hibridoPlugIn: 0,
        wltp: 0,
        particulas: 0,
        dataPrimeiroRegisto: '',
        tipoDeVeiculoParaOutros: 'LigeiroPassageiros',
        tipoDeVeiculoParaInsencoes: '',
        outrosServicos: '',
        valorTotalISV: 0,
        valorTotalIUC: 0
    });

    //Valores Totais
    const [valoresTotais, setValoresTotais] = useState({
        precoViaturaComIva: 0,
        precoViaturaSemIva: 0,
        taxaDeServico: 2800,
        taxaDeFinanciamento: 400,
        taxaDeFinanciamentoAtivo: 0,
        valorExpress: 1000,
        valorExpressAtivo: 0,
        comissao: 0,
        valorTotalISV: 0,
        valorTotalIUC: 0,
        valorPrecoChaveNaMaoParticular: 0,
        valorPrecoChaveNaMaoEmpresa: 0,
        compsIUC: {
            iucCilindrada: 0,
            iucCO2: 0,
            iucAdicionalCO2: 0,
            iucCoeficienteAnoMatriculo: 0,
            iucAdicionalGasoleo: 0
        },
        compsISV: {
            isvcomponentecilindrada: 0,
            isvcomponenteambiental: 0,
            isvReducaoAnosUsoCilindrada: 0,
            isvReducaoAnosUsoAmbiental: 0,
            isvAgravamentoParticulas: 0,
            isvReducaoAnosUsoParticulas: 0,
            isvTaxaAplicavelTabela: '',
        }
    });


    // Parsea o nome da proposta da URL usando URLSearchParams
    useEffect(() => {
        const fetchProposta = async () => {

            if (!propostaData) return;

            const allDataCarro = propostaData.data.carro;
            const allValores = propostaData.data.simulacao;
            const allValoresExtra = propostaData.data.simulacao_extras;
            const allProposta = propostaData.data.proposta;
            const allStand = propostaData.data.stand;

            setDataCarros(allDataCarro);

            setPropostaInfo((prevData) => ({
                ...prevData,
                linkFora: allProposta.link_proposta,
                linkDaProposta: allProposta.link_proposta_view,
                estadoDaProposta: allProposta.estado_proposta_hubspot,
                tipoNegocio: allProposta?.documentacao?.info?.tipo_contrato || "",
                idProposta: allProposta.id_proposta,
                pais: allProposta.pais,
                standData: allStand,
                outros: allProposta,
                dataExpira: allProposta.data_expira,
                nomeCliente: allProposta.hubspot?.contacto?.firstname,
                linkDocumentacao: allProposta.link_documentacao
            }));

            setIsvIucData((prevData) => ({
                ...prevData,
                cilindrada: allValores.cilindrada,
                novoUsado: allValores.novo,
                tipoCombustivel: allValores.tipo_de_combustivel,
                emissoesCO2: allValores.emissoes_numerico,
                hibridoPlugIn: allValores.hibrido,
                wltp: allValores.wltp,
                dataPrimeiroRegisto: allValores.data_registo,
                valorTotalISV: allValores.isv,
                valorTotalIUC: allValores.iuc,
            }));

            setValoresTotais((prevData) => ({
                ...prevData,
                precoViaturaComIva: allDataCarro.preco_final_carro,
                precoViaturaSemIva: allDataCarro.preco_final_carro_sem_iva,
                taxaDeServico: allValores.taxadeservico,
                taxaDeFinanciamento: allValores.comissao_credito,
                taxaDeFinanciamentoAtivo: allValores.check_comissao_credito,
                valorExpress: allValores.comissao_express,
                valorExpressAtivo: allValores.check_comissao_express,
                comissao: allValores.comissaodeservico,
                valorTotalISV: allValores.isv,
                valorTotalIUC: allValores.iuc,
                valorPrecoChaveNaMaoParticular: allValores.valor_chavenamao_particular,
                valorPrecoChaveNaMaoEmpresa: allValores.valor_chavenamao_empresa,
                compsIUC: {
                    iucCilindrada: allValoresExtra.iuc_cilindrada,
                    iucCO2: allValoresExtra.iuc_co2,
                    iucAdicionalCO2: allValoresExtra.iuc_adicional_co2,
                    iucCoeficienteAnoMatriculo: allValoresExtra.iuc_coeficiente_ano_matriculo,
                    iucAdicionalGasoleo: allValoresExtra.iuc_adicional_gasoleo,
                },
                compsISV: {
                    isvcomponentecilindrada: allValoresExtra.isv_componente_cilindrada,
                    isvcomponenteambiental: allValoresExtra.isv_componente_ambiental,
                    isvReducaoAnosUsoCilindrada: allValoresExtra.isv_reducao_anos_uso_cilindrada,
                    isvReducaoAnosUsoAmbiental: allValoresExtra.isv_reducao_anos_uso_ambiental,
                    isvAgravamentoParticulas: allValoresExtra.isv_agravamento_particulas,
                    isvReducaoAnosUsoParticulas: allValoresExtra.isv_reducao_anos_uso_particulas,
                    isvTaxaAplicavelTabela: allValoresExtra.isv_taxa_aplicavel_tabela,
                }
            }));

            setBDInfo(propostaData.data.user)
            // onDataLoaded();
        };

        fetchProposta();
    }, [propostaData]); // Fim do useEffect

    // console.log(propostaInfo.estadoDaProposta)

    return (
        <div className={styles.container}>
            <div className={styles.gridContainer}>
                <HeroProposta
                    propostaInfo={propostaInfo}
                    isDarkTheme={isDarkTheme}
                    parametros={parametros}
                    valoresTotais={valoresTotais}
                    setValoresTotais={setValoresTotais}
                    setPropostaInfo={setPropostaInfo}
                />
                <div className={styles.flexRow}>
                    <div className={styles.sectionCarDetails}>
                        <TabelaDadosCarroProposta
                            dataCarros={dataCarros}
                            isvIucData={isvIucData}
                            propostaInfo={propostaInfo}
                            parametros={parametros}
                            isDarkTheme={isDarkTheme}
                        />
                    </div>
                    <div className={styles.sectionCalculation}>
                        <FormCamposCalculoPropostaPT
                            isvIucData={isvIucData}
                            isDarkTheme={isDarkTheme}
                        />
                        {propostaInfo.estadoDaProposta === "proposta_aberta" && (
                            <ExpressProposta
                                valoresTotais={valoresTotais}
                                setValoresTotais={setValoresTotais}
                                parametros={parametros}
                                isDarkTheme={isDarkTheme}
                            />
                        )}
                        <PrecoFinalProposta
                            valoresTotais={valoresTotais}
                            setValoresTotais={setValoresTotais}
                            parametros={parametros}
                            isDarkTheme={isDarkTheme}
                        />
                        <EstimativaDaViatura
                            propostaInfo={propostaInfo}
                            valoresTotais={valoresTotais}
                            isDarkTheme={isDarkTheme}
                        />
                        <InfosBusinessDeveloper
                            bdInfo={bdInfo}
                            isDarkTheme={isDarkTheme}
                        />
                        <Partilhar
                            propostaInfo={propostaInfo}
                            isDarkTheme={isDarkTheme}
                            parametros={parametros}
                            bdInfo={bdInfo}
                        />
                        {propostaInfo.estadoDaProposta === "proposta_aberta" && (
                            <AceitarProposta
                                propostaInfo={propostaInfo}
                                isDarkTheme={isDarkTheme}
                                parametros={parametros}
                                valoresTotais={valoresTotais}
                                setValoresTotais={setValoresTotais}
                                setPropostaInfo={setPropostaInfo}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
