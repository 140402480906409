import React from 'react';
import styles from './HeroProposta.module.css';
import { Button, Tooltip } from 'antd';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import AceitarProposta from '../Elements/AceitarProposta/AceitarProposta';
import { useTranslation } from 'react-i18next';

export default function HeroProposta({ isDarkTheme, setPropostaInfo, parametros, propostaInfo, valoresTotais, setValoresTotais }) {

    const { t } = useTranslation();

    return (
        <div className={`${isDarkTheme ? styles.cardDark : styles.cardLight}`}>
            <div className={styles.flexContainer}>
                <div>
                    <p className={`${styles.name} ${isDarkTheme ? styles.nameDark : styles.nameLight}`}>
                        {t('view.ola')}, {propostaInfo.nomeCliente ? `${propostaInfo.nomeCliente} 👋` : null}
                    </p>
                    <p className={styles.expiryText}>
                        {t('view.proposta_expira')} {propostaInfo.dataExpira}
                        <Tooltip title={t('view.proposta_expira_info')}>
                            <AiOutlineInfoCircle className={styles.iconInfo} />
                        </Tooltip>
                    </p>
                </div>
                {parametros.language === 'pt' && (
                    <AceitarProposta
                        propostaInfo={propostaInfo}
                        isDarkTheme={isDarkTheme}
                        parametros={parametros}
                        valoresTotais={valoresTotais}
                        setValoresTotais={setValoresTotais}
                        setPropostaInfo={setPropostaInfo}
                    />
                )}
            </div>
        </div>
    );
}
