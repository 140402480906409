
import React from 'react';
import { useTranslation } from 'react-i18next';
import PrecoChaveNaMaoProposta from '../../../Propostas/PrecoFinalProposta/precoChaveNaMao';
import { Tooltip } from 'antd';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import ExpressInfoDrawer from '../../../Propostas/ExpressProposta/ExpressInfoDrawer';

export default function AceitarPropostaPrecosEmpresa({ valoresTotais, setValoresTotais, isDarkTheme }) {

    const { t } = useTranslation();

    const formatarNumero = (valor) => {
        if (!valor || isNaN(valor)) return '0 €';
        return new Intl.NumberFormat('pt-PT', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(valor);
    };

    return (
        <div className='flex flex-col gap-1'>

            <p className='mb-3'>{t('view.validar_valores_finais')}</p>

            {/* Preço Viatura sem IVA (apenas para Empresa) */}
            <div className='flex justify-between items-center'>
                <p className='text-base'>{t('gerar_proposta.valor_viatura')}</p>
                <p className='font-bold text-lg'>
                    {formatarNumero(valoresTotais.precoViaturaSemIva)}
                </p>
            </div>

            {/* Valor Total ISV */}
            <div className='flex justify-between items-center'>
                <p className='text-base'>{t('gerar_proposta.isv')}</p>
                <p className='font-bold text-lg'>
                    <p className='font-bold text-lg'>
                        {valoresTotais.valorTotalISV == 0 ? (
                            t('gerar_proposta.isento')
                        ) : (
                            `${formatarNumero(valoresTotais.valorTotalISV)}`
                        )}
                    </p>
                </p>
            </div>


            {/* Taxa de Serviço */}
            <div className='flex justify-between items-center'>
                <p className='text-base'>{t('gerar_proposta.taxa_servico')}</p>
                <p className='font-bold text-lg'>
                    {formatarNumero(valoresTotais.taxaDeServico)}
                </p>
            </div>

            {/* Taxa de Financiamento */}
            {valoresTotais.taxaDeFinanciamentoAtivo == 1 && (
                <div className='flex justify-between items-center'>
                    <p className='text-base'>{t('gerar_proposta.taxa_financiamento')}</p>
                    <p className='font-bold text-lg'>
                        {formatarNumero(valoresTotais.taxaDeFinanciamento)}
                    </p>
                </div>
            )}

            {valoresTotais.valorExpressAtivo == 1 && (
                <div className='flex justify-between items-center'>
                    <span className='text-base flex items-center gap-2'>
                        Express
                        <ExpressInfoDrawer isDarkTheme={isDarkTheme} />
                    </span>
                    <p className='font-bold text-lg'>
                        {formatarNumero(valoresTotais.valorExpress)}
                    </p>
                </div>
            )}

            {/* Preço Chave na Mão */}
            <PrecoChaveNaMaoProposta
                activeTabKey={'2'}
                valoresTotais={valoresTotais}
                setValoresTotais={setValoresTotais}
                formatarNumero={formatarNumero}
            />


            {/* Valor Total IUC */}
            {/* <div className='flex justify-between items-center'>
                <p className='text-base'>{t('gerar_proposta.iuc')}</p>
                <p className='font-bold text-lg'>
                    {valoresTotais.valorTotalIUC == 0 ? (
                        t('gerar_proposta.isento')
                    ) : (
                        `${formatarNumero(valoresTotais.valorTotalIUC)}`
                    )}
                </p>
            </div> */}

        </div>
    );
}
