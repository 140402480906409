import React, { useState } from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './PrecoFinalProposta.module.css';
import PropsPrecoFinalProposta from './PrecoFinalProposta/propsPrecoFinalProposta';

export default function PrecoFinalProposta({ valoresTotais, setValoresTotais, parametros, isDarkTheme }) {

    const { t } = useTranslation();
    const [activeTabKey, setActiveTabKey] = useState("1");

    const formatarNumero = (valor) => {
        if (!valor || isNaN(valor)) return '0 €';
        return new Intl.NumberFormat('pt-PT', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(valor);
    };

    const validarValordaViatura = () => {
        if (valoresTotais.precoViaturaComIva === valoresTotais.precoViaturaSemIva) {
            return t('gerar_proposta.validar_valor_viatura');
        }
        return '';
    };

    const onTabChange = (key) => {
        setActiveTabKey(key);
    };

    const items = [
        {
            label: <span className={styles.tabLabel}>{t('main.particular')}</span>,
            key: '1',
            children: (
                <PropsPrecoFinalProposta
                    setValoresTotais={setValoresTotais}
                    valoresTotais={valoresTotais}
                    incluirIVA={true}
                    activeTabKey={activeTabKey}
                    formatarNumero={formatarNumero}
                    parametros={parametros}
                    isDarkTheme={isDarkTheme}
                />
            ),
        },
        {
            label: <span className={styles.tabLabel}>{t('main.empresa')}</span>,
            key: '2',
            children: (
                <PropsPrecoFinalProposta
                    setValoresTotais={setValoresTotais}
                    valoresTotais={valoresTotais}
                    incluirIVA={false}
                    activeTabKey={activeTabKey}
                    formatarNumero={formatarNumero}
                    parametros={parametros}
                    isDarkTheme={isDarkTheme}
                />
            ),
        },
    ];

    return (
        <div className={`${isDarkTheme ? styles.cardDark : styles.cardLight}`}>
            <div className={styles.header}>
                <div>
                    <p className={`${isDarkTheme ? styles.darkTitle : styles.whiteTitle}`}>{t('gerar_proposta.resultado_simucao')}</p>
                </div>
            </div>
            {/* <hr className={styles.separator} /> */}

            <Tabs
                className={styles.tabsContainer}
                activeKey={activeTabKey}
                onChange={onTabChange}
                defaultActiveKey="1"
                tabBarGutter={16}
                centered
                items={items}
            />

            {validarValordaViatura() && (
                <>
                    <hr className={styles.separator} />
                    <div className={styles.validationMessage}>
                        {validarValordaViatura()}
                    </div>
                </>
            )}
        </div>
    );
};
