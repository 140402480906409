import React, { useMemo, useState } from 'react';
import { Form, Input, Button, Upload, Select, message, Space } from 'antd';
import axios from 'axios';
import { UploadOutlined } from '@ant-design/icons';
import styles from './StatusPendingDocumentos.module.css';
import { useTranslation } from 'react-i18next';
import { IoMdCheckmarkCircle, IoMdCloseCircle } from 'react-icons/io';
import { FaCheckCircle } from 'react-icons/fa';

const { Option } = Select;

export default function StatusPendingDocumentos({ data, setData, parametros }) {

    const { t } = useTranslation();

    const [form] = Form.useForm();


    const handleSubmit = (values) => {

        const formData = new FormData();

        formData.append('tipo_documento_identificacao', values.tipoDocumento || '');
        formData.append('certidao_permanente', values.certidaoPermanente || '');

        if (values.documentoIdentificacao?.fileList) {
            values.documentoIdentificacao.fileList.forEach(file => {
                formData.append('documento_identificacao[]', file.originFileObj);
            });
        }

        if (values.documentoConducao?.fileList) {
            values.documentoConducao.fileList.forEach(file => {
                formData.append('documento_conducao[]', file.originFileObj);
            });
        }

        if (data.tipoNegocio !== "empresa") {
            const moradaData = [
                values.morada,
                values.codigoPostal,
                values.cidade
            ].filter(Boolean).join('|');
            formData.append('documento_morada', moradaData || '');
        }

        formData.append('token_acesso', parametros.docToken);

        axios.post(`${process.env.REACT_APP_UPLOUD_DOCUMENTOS}`, formData)
            .then(response => {
                if (response.data.sucesso) {
                    message.success(response.data.mensagem);
                    setData((prevData) => ({
                        ...prevData,
                        status: response.data.documentacao_enviada,
                    }));
                } else {
                    message.error(response.data.mensagem);
                }
            })
            .catch(error => {
                message.error('Erro, contacte o responsável!');
            });
    };

    const handleCodigoPostalChange = (value, part) => {
        let inicio = '';
        let fim = '';

        if (data.codigoPostal) {
            [inicio, fim] = data.codigoPostal.split('-'); // Se já houver valor, dividir o código postal
        }

        if (value === "") {
            setData((prevData) => ({
                ...prevData,
                codigoPostal: '',
            }));
        } else {
            if (part === 'inicio') {
                inicio = value; // Atribuir o valor ao início
            } else if (part === 'fim') {
                fim = value; // Atribuir o valor ao fim
            }

            // Calcular o código postal formatado com base nos valores atuais
            const codigoPostalFormatado = `${inicio}-${fim}`;
            setData((prevData) => ({
                ...prevData,
                codigoPostal: codigoPostalFormatado,
            }));
        }
    };


    // console.log(data.estadosDocumentos)
    // const rejectedDocuments = data.estadosDocumentos?.filter(doc => doc.status === 'rejected') || [];


    return (
        <>
            <div className="grid gap-1">
                <p className="text-lg font-bold">Documentos</p>
                <p className="text-sm font-normal">Para avançar com o negócio, envie os seguintes documentos:</p>
            </div>
            <hr />
            <Form
                layout="vertical"
                className={styles.form}
                form={form}
                onFinish={handleSubmit}
            >



                {/* {data.tipoNegocio == "particular" && ( */}
                <div className='grid'>
                    {data.estadosDocumentos?.find(doc => doc.nome === "Documento de Identificação")?.status !== 'accepted' && (
                        <div className="grid gap-2 content-start">
                            <p className='font-medium text-white' >Tipo de Documento de Identificação</p>
                            <Form.Item
                                name="tipoDocumento"
                                className='icon-style'
                                rules={[{ required: true, message: 'Por favor selecione o tipo de documento!' }]}
                            >
                                <Select placeholder="Selecione o tipo de documento">
                                    <Option value="cc">Cartão de Cidadão (CC)</Option>
                                    <Option value="bi">Bilhete de Identidade (BI)</Option>
                                    <Option value="passaporte">Passaporte</Option>
                                </Select>
                            </Form.Item>
                        </div>
                    )}

                    <div className="grid gap-2 content-start ">
                        <div className='flex gap-2 items-center'>
                            <p className='font-medium text-white'>Documento de Identificação</p>
                            {data.estadosDocumentos?.find(doc => doc.nome === "Documento de Identificação")?.status === 'accepted' && (
                                <FaCheckCircle style={{ fontSize: '18px', color: '#0D9F57' }} />
                            )}
                        </div>
                        {data.estadosDocumentos?.find(doc => doc.nome === "Documento de Identificação")?.status !== 'accepted' && (
                            <Form.Item
                                name="documentoIdentificacao"
                                valuePropName="file"
                                className='icon-style'
                                rules={[{ required: true, message: 'Por favor faça upload do documento de identificação!' }]}
                            >
                                <Upload multiple beforeUpload={() => false}>
                                    <Button className='shadow-none font-bold text-black bg-white' icon={<UploadOutlined className='text-black' />}>
                                        Carregar Documento
                                    </Button>
                                </Upload>
                            </Form.Item>
                        )}
                    </div>
                </div>
                {/* )} */}


                {data.tipoNegocio == "particular" && (
                    <div className="grid gap-2 content-start">
                        <div className="flex gap-2 items-center">
                            <p className="font-medium text-white">Morada</p>
                            {data.estadosDocumentos?.find(doc => doc.nome === "Morada")?.status === 'accepted' && (
                                <FaCheckCircle style={{ fontSize: '18px', color: '#0D9F57' }} />
                            )}
                        </div>
                        {data.estadosDocumentos?.find(doc => doc.nome === "Morada")?.status !== 'accepted' && (
                            <div className="grid content-start">
                                <Form.Item
                                    name="morada"
                                    className="icon-style"
                                    rules={[{ required: true, message: 'Por favor, insira a morada!' }]}
                                >
                                    <Input
                                        placeholder="Rua Camilo Castelo Branco nº2, 4º esquerdo"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="codigoPostal"
                                    className="icon-style"
                                    rules={[{ required: true, message: 'Por favor, insira o código postal!' }]}
                                >
                                    <div className="grid gap-2 content-start">
                                        <p className="font-medium text-white">Código Postal</p>
                                        <Space.Compact>
                                            <Input
                                                style={{ width: '40%' }}
                                                placeholder="1150"
                                                onChange={(e) => {
                                                    const value = e.target.value.replace(/\D/g, ''); // Remove tudo que não for número
                                                    handleCodigoPostalChange(value, 'inicio');
                                                }}
                                                type="text"
                                                maxLength={4}
                                                required
                                            />
                                            <Input
                                                style={{ width: '40%' }}
                                                placeholder="084"
                                                onChange={(e) => {
                                                    const value = e.target.value.replace(/\D/g, ''); // Remove tudo que não for número
                                                    handleCodigoPostalChange(value, 'fim');
                                                }}
                                                type="text"
                                                maxLength={3}
                                                required
                                            />
                                        </Space.Compact>
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    name="cidade"
                                    className="icon-style"
                                    rules={[{ required: true, message: 'Por favor, insira a cidade!' }]}
                                >
                                    <div className="grid gap-2 content-start">
                                        <p className="font-medium text-white">Cidade</p>
                                        <Input
                                            placeholder="Lisboa"
                                        />
                                    </div>
                                </Form.Item>

                            </div>
                        )}
                    </div>
                )}

                {data.tipoNegocio == "empresa" && (
                    <div className="grid gap-2 content-start">
                        <div className='flex gap-2 items-center'>
                            <p className='font-medium text-white'>Código da Certidão Permanente</p>
                            {data.estadosDocumentos?.find(doc => doc.nome === "Certidão Permanente")?.status === 'accepted' && (
                                <FaCheckCircle style={{ fontSize: '18px', color: '#0D9F57' }} />
                            )}
                        </div>
                        {data.estadosDocumentos?.find(doc => doc.nome === "Certidão Permanente")?.status !== 'accepted' && (
                            <Form.Item
                                className='icon-style'
                                // label="Certidão Permanente"
                                name="certidaoPermanente"
                                rules={[{ required: true, message: 'Por favor insira o código da certidão permanente!' }]}
                            >
                                <Input placeholder="Insira o código da certidão permanente" />
                            </Form.Item>
                        )}
                    </div>
                )}

                {/* {data.tipoNegocio == "particular" && ( */}
                <div className="grid gap-2 content-start">
                    <div className='flex gap-2 items-center'>
                        <p className='font-medium text-white'>Carta de Condução</p>
                        {data.estadosDocumentos?.find(doc => doc.nome === "Carta de Condução")?.status === 'accepted' && (
                            <FaCheckCircle style={{ fontSize: '18px', color: '#0D9F57' }} />
                        )}
                    </div>
                    {data.estadosDocumentos?.find(doc => doc.nome === "Carta de Condução")?.status !== 'accepted' && (
                        <Form.Item
                            name="documentoConducao"
                            valuePropName="file"
                            className='icon-style'
                            rules={[{ required: true, message: 'Por favor faça upload da carta de condução' }]}
                        >
                            <Upload multiple beforeUpload={() => false}>
                                <Button className='shadow-none font-bold text-black bg-white' icon={<UploadOutlined />}>Carregar Documento</Button>
                            </Upload>
                        </Form.Item>
                    )}
                </div>
                {/* )} */}

                <hr className='mt-5' />

                <Form.Item>
                    <Button type="primary" htmlType="submit" className={styles.submitButton}>
                        {t('documentos.enviarDocumentos')}
                    </Button>
                </Form.Item>

            </Form >
        </>
    );
}
