import React, { useState } from 'react';
import styles from './Partilhar.module.css';
import { FiExternalLink, FiCopy, FiCheck } from 'react-icons/fi';
import { FaWhatsapp, FaTelegramPlane, FaFacebook, FaInstagram, FaEnvelope } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Button, message } from 'antd';
import { HiOutlineMail } from 'react-icons/hi';

export default function Partilhar({ isDarkTheme, propostaInfo, parametros, bdInfo }) {

    const { t } = useTranslation();
    const [isCopied, setIsCopied] = useState(false);

    const currentDomain = window.location.origin;
    const shareUrl = `${currentDomain}/?n=${parametros.n}`;
    // const shareMessage = `${t('view.sharemessage1')}${parametros.n} ${t('view.sharemessage2')}`;

    // console.log(bdInfo.partilha_whatsapp)

    const handleCopy = () => {
        navigator.clipboard.writeText(shareUrl);
        setIsCopied(true);
        message.success('Copiado')
        setTimeout(() => {
            setIsCopied(false);
        }, 2000);
    };

    return (
        <div className={isDarkTheme ? styles.cardDark : styles.cardLight}>
            <div className={styles.contain}>
                {t('tabela_viatura.partilhar')}
                <div className={styles.buttonRow}>
                    <Button
                        onClick={() => window.open(`${bdInfo.partilha_whatsapp}`, '_blank')}
                        className={styles.whatsAppButton}
                        rel="noopener noreferrer"
                    >
                        <FaWhatsapp className={styles.icon} />
                    </Button>
                    <Button
                        onClick={() => window.open(`mailto:${bdInfo.partilha_email}`, '_blank')}
                        className={styles.emailButton}
                        rel="noopener noreferrer"
                    >
                        <HiOutlineMail className={styles.icon} />
                    </Button>
                    <Button
                        onClick={handleCopy}
                        className={styles.copyButton}
                        rel="noopener noreferrer"
                    >
                        {isCopied ? <FiCheck className={styles.icon} /> : <FiCopy className={styles.icon} />}
                    </Button>
                </div>
            </div>
        </div>
    );
}
