import { List } from 'antd';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import styles from './TabelaDadosCarroProposta.module.css';

export default function TabelaDadosCarroProposta({ dataCarros, isvIucData, propostaInfo, parametros, isDarkTheme }) {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <div className={`${isDarkTheme ? styles.cardDark : styles.cardLight}`}>
                <div className={styles.imageGallery}>
                    <Swiper navigation={true} modules={[Navigation]}>
                        {dataCarros.imagens && dataCarros.imagens.length > 0 && dataCarros.imagens.map((imagem, index) => (
                            <SwiperSlide key={index}>
                                <img className={styles.image} src={imagem} alt={`Image ${index + 1}`} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

                <p className={styles.title}>
                    {dataCarros.titulo}
                </p>

                <div className={styles.infoGrid}>
                    <p className={styles.individualInfo}>
                        {t('tabela_viatura.quilometros')}:
                        <span className={`${isDarkTheme ? styles.infoText : styles.infoTextLight}`}>
                            {dataCarros.quantidade_de_km || t('tabela_viatura.sem_informacao')}
                        </span>
                    </p>

                    <p className={styles.individualInfo}>
                        {t('tabela_viatura.data_registo')}:
                        <span className={`${isDarkTheme ? styles.infoText : styles.infoTextLight}`}>
                            {isvIucData.dataPrimeiroRegisto || t('tabela_viatura.sem_informacao')}
                        </span>
                    </p>

                    {isvIucData.cilindrada && (
                        <p className={styles.individualInfo}>
                            {t('tabela_viatura.cilindrada')}:
                            <span className={`${isDarkTheme ? styles.infoText : styles.infoTextLight}`}>
                                {isvIucData.cilindrada ? `${isvIucData.cilindrada} ccm` : t('tabela_viatura.sem_informacao')}
                            </span>
                        </p>
                    )}

                    <p className={styles.individualInfo}>
                        {t('tabela_viatura.potencia')}:
                        <span className={`${isDarkTheme ? styles.infoText : styles.infoTextLight}`}>
                            {dataCarros.potencia?.toString().includes('Hp')
                                ? dataCarros.potencia.replace('Hp', 'CV')
                                : `${dataCarros.potencia || ''} CV`}
                        </span>
                    </p>

                    <p className={styles.individualInfo}>
                        {t('tabela_viatura.combustivel')}:
                        <span className={`${isDarkTheme ? styles.infoText : styles.infoTextLight}`}>
                            {isvIucData.tipoCombustivel || t('tabela_viatura.sem_informacao')}
                        </span>
                    </p>

                    {isvIucData.tipoCombustivel !== "Eletrico" && (
                        <p className={styles.individualInfo}>
                            {t('tabela_viatura.emissoes')}:
                            <span className={`${isDarkTheme ? styles.infoText : styles.infoTextLight}`}>
                                {isvIucData.emissoesCO2 || t('tabela_viatura.sem_informacao')}
                            </span>
                        </p>
                    )}
                </div>

            </div>

            <div className={`${isDarkTheme ? styles.cardDark : styles.cardLight}`}>
                <p className={`${isDarkTheme ? styles.sectionHeaderDark : styles.sectionHeaderLight}`}>{t('tabela_viatura.mais_informacao')}</p>
                <List
                    size="small"
                    dataSource={[
                        { title: `${t('tabela_viatura.acidentes')}`, value: dataCarros.acidentes },
                        { title: `${t('tabela_viatura.tipo_de_caixa')}`, value: dataCarros.tipo_de_caixa },
                        { title: `${t('tabela_viatura.stand')}`, value: propostaInfo.standData.nome_stand },
                        { title: `${t('tabela_viatura.pais')}`, value: propostaInfo.standData.pais_stand },
                        { title: `${t('tabela_viatura.codigo_postal')}`, value: propostaInfo.standData.codigo_postal_stand },
                        { title: `${t('tabela_viatura.morada')}`, value: propostaInfo.standData.morada_stand },
                        { title: `${t('tabela_viatura.contacto')}`, value: propostaInfo.standData.contacto_stand },
                        { title: `${t('tabela_viatura.contacto_2')}`, value: propostaInfo.standData.contacto_2_stand },
                    ]}
                    renderItem={(item) => (
                        <List.Item>
                            <div className={`${isDarkTheme ? styles.infoItemDark : styles.infoItemLight}`}>
                                <p>{item.title}:</p>
                                <span>{item.value ? item.value : `${t('tabela_viatura.sem_informacao')}`}</span>
                            </div>
                        </List.Item>
                    )}
                />
            </div>

            <div className={`${isDarkTheme ? styles.cardDark : styles.cardLight}`}>
                <div className={`${isDarkTheme ? styles.infoItemDark : styles.infoItemLight}`}>
                    <p>{t('tabela_viatura.ver_anuncio')}:</p>
                    <span>
                        <a href={propostaInfo.linkFora} target="_blank" rel="noopener noreferrer" className={`${isDarkTheme ? styles.linkDark : styles.link}`}>
                            Link
                            {/* <FaLink /> */}
                        </a>
                    </span>
                </div>
            </div>

        </div>
    );
}
