import React, { useState } from 'react';
import styles from './AceitarProposta.module.css';
import { Button, Modal, Select, Spin, message } from 'antd';
import { useTranslation } from 'react-i18next';
import AceitarPropostaPrecosEmpresa from './ModalAceitarProposta/AceitarPropostaPrecosEmpresa';
import AceitarPropostaPrecosParticular from './ModalAceitarProposta/AceitarPropostaPrecosParticular';
import AceitarPropostaModalPropostaAceite from './ModalAceitarProposta/AceitarPropostaModalPropostaAceite';
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;

export default function AceitarProposta({ isDarkTheme, parametros, setPropostaInfo, propostaInfo, valoresTotais, setValoresTotais }) {

    const { t } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loadingPropostaAceite, setLoadingPropostaAceite] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [data, setData] = useState({ 
        negocio: '' ,
        // linkParaEnvioDocumento:''
    });

    const handleAceitarProposta = () => {
        setIsModalVisible(true);
    };

    const handleCancelar = () => {
        setIsModalVisible(false);
        setIsConfirmed(false);
    };

    const handleConfirmar = async () => {

        if (!data.negocio) {
            message.warning(t('view.alerta_tipo_negocio'))
            return;
        }

        setLoadingPropostaAceite(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_PROPOSTA_ACEITE}`,
                {
                    nome_proposta: parametros.n,
                    check_comissao_express: valoresTotais.valorExpressAtivo,
                    tipo_contrato: data.negocio
                }
            );

            if (response.data.sucesso) {
                setIsConfirmed(true);

                setPropostaInfo((prevData) => ({
                    ...prevData,
                    estadoDaProposta:response.data.estado_proposta,
                    linkDocumentacao: response.data.link_acesso
                }));

                // setData((prevData) => ({
                //     ...prevData,
                //     linkParaEnvioDocumento:response.data.link_acesso
                // }));

            } else {
                setIsConfirmed(false);
            }
        } catch (error) {
            setIsConfirmed(false);
        } finally {
            setLoadingPropostaAceite(false);
        }
    };

    return (
        <div className={styles.containButton}>
            {propostaInfo.estadoDaProposta === "proposta_aberta" ? (

                <Button
                    htmlType="button"
                    onClick={handleAceitarProposta}
                    className={styles.acceptButton}
                >
                    {t('view.aceitar_proposta')}
                </Button>

            ) : propostaInfo.estadoDaProposta === "aceitou_outra_proposta" ? (
                <div className={styles.card}>
                    <div className={styles.otherProposal}>
                        {t('view.outra_proposta')}
                    </div>
                </div>
            ) : propostaInfo.estadoDaProposta === "proposta_aceite" ? (
                <div className={styles.card}>
                    <div className={styles.acceptedProposal}>
                        {t('view.proposta_aceite_title')}
                    </div>
                    <a href={propostaInfo.linkDocumentacao}>Enviar documentação</a>
                </div>
            ) : null}

            <Modal
                title={!isConfirmed ? t('view.deseja_aceitar') : null}
                open={isModalVisible}
                onCancel={handleCancelar}
                footer={!loadingPropostaAceite && !isConfirmed ? [
                    <div className={styles.acceptDiv}>
                        <p>
                            {t('view.ao_aceitar_proposta')}
                        </p>
                        <Button
                            key="confirm"
                            onClick={handleConfirmar}
                            className={styles.acceptButton}
                        >
                            {t('view.aceitar_proposta')}
                        </Button>
                    </div>

                ] : null}
                closable={!loadingPropostaAceite}
                centered
                className={`${isDarkTheme ? styles.modalDark : styles.modalLight}`}
            >
                {loadingPropostaAceite ? (
                    <div className="flex items-center gap-5 flex-col justify-center text-md p-2.5">
                        <Spin
                            indicator={
                                <LoadingOutlined
                                    className={isDarkTheme ? styles.spinnerDark : styles.spinnerWhite}
                                    spin
                                />
                            }
                        />
                        <p className="text-black"> {t('view.aguarde_por_favor')}</p>
                    </div>
                ) : isConfirmed ? (
                    <AceitarPropostaModalPropostaAceite isDarkTheme={isDarkTheme} propostaInfo={propostaInfo} />
                ) : (
                    <>
                        <div className="grid gap-5">
                            <p>{t('view.confirme_tipo_negocio')}</p>
                            <Select
                                placeholder="Selecione o tipo de negócio"
                                onChange={(value) => setData({ negocio: value })}
                                value={data.negocio}
                            >
                                <Option value="particular">Particular</Option>
                                <Option value="empresa">Empresa</Option>
                            </Select>

                            {data.negocio === "particular" && (
                                <AceitarPropostaPrecosParticular
                                    valoresTotais={valoresTotais}
                                    setValoresTotais={setValoresTotais}
                                    isDarkTheme={isDarkTheme}
                                />
                            )}

                            {data.negocio === "empresa" && (
                                <AceitarPropostaPrecosEmpresa
                                    valoresTotais={valoresTotais}
                                    setValoresTotais={setValoresTotais}
                                    isDarkTheme={isDarkTheme}
                                />
                            )}

                            <hr></hr>

                        </div>
                    </>
                )}
            </Modal>
        </div>
    );
}
