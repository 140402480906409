import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './TabelaDadosCarroProposta.module.css';

export default function EstimativaDaViatura({ propostaInfo, isDarkTheme, valoresTotais }) {
    const { t } = useTranslation();

    // Estados para armazenar os valores de tempo
    const [tempoRecolha, setTempoRecolha] = useState(`${propostaInfo.outros.tempo_recolha || ''} semanas`);
    const [tempoEntrega, setTempoEntrega] = useState(`${propostaInfo.outros.tempo_entrega || ''} semanas`);
    const [estimativaEntrega, setEstimativaEntrega] = useState(`${propostaInfo.outros.estimativa_entrega || ''} semanas`);
    const [corTexto, setCorTexto] = useState('');
    const [corTextoFinal, setCorTextoFinal] = useState('');


    useEffect(() => {
        if (valoresTotais.valorExpressAtivo === 1) {
            setTempoRecolha('5 dias');
            setTempoEntrega('10 dias');
            setEstimativaEntrega('15 dias');
            setCorTexto(styles.highlightText); 
            setCorTextoFinal(styles.highlightTextFinal); 
        } else {
            setTempoRecolha(`${propostaInfo.outros.tempo_recolha} semanas`);
            setTempoEntrega(`${propostaInfo.outros.tempo_entrega} semanas`);
            setEstimativaEntrega(`${propostaInfo.outros.estimativa_entrega} semanas`);
            setCorTexto(''); 
            setCorTextoFinal(''); 
        }
    }, [valoresTotais.valorExpressAtivo, propostaInfo.outros]);

    return (
        <div className={`${isDarkTheme ? styles.cardDark : styles.cardLight}`}>
            <p className={`${isDarkTheme ? styles.darkTitle : styles.whiteTitle}`}>Duração do processo</p>
            
            <div className={styles.estimateDetail}>
                <div className={styles.estimateRow}>
                    <div className={styles.estimateLabel}>
                        <p className={`${isDarkTheme ? styles.infoTooltipDark : styles.infoTooltipLight}`}>
                            Tempo de recolha
                        </p>
                    </div>
                    <span className={`${isDarkTheme ? styles.estimateValueDark : styles.estimateValueLight} ${corTexto}`}>
                        {tempoRecolha}
                    </span>
                </div>
                
                <div className={styles.estimateRow}>
                    <div className={styles.estimateLabel}>
                        <p className={`${isDarkTheme ? styles.infoTooltipDark : styles.infoTooltipLight}`}>
                            Tempo de entrega
                        </p>
                    </div>
                    <span className={`${isDarkTheme ? styles.estimateValueDark : styles.estimateValueLight} ${corTexto}`}>
                        {tempoEntrega}
                    </span>
                </div>
            </div>
            
            <hr className={styles.separator} />
            
            <div className={styles.estimateRow}>
                <div className={styles.estimateLabel}>
                    <p className={`${isDarkTheme ? styles.infoFinalTooltipDark : styles.infoFinalTooltipLight}`}>
                        Tempo estimado até à entrega
                    </p>
                </div>
                <span className={`${styles.finalValue} ${corTextoFinal}`}>
                    {estimativaEntrega}
                </span>
            </div>
        </div>
    );
};
